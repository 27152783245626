import React from 'react';
import dynamic from 'next/dynamic';
import type { GetStaticProps } from 'next';

import { isDev, domain } from '@/utils/config/environment';
import { getSiteMaps } from '@/utils/notion/getSiteMaps';
import { resolvePage } from '@/utils/notion/resolvePage';

const NotionPage = dynamic(async () => (await import('@/components')).NotionPage);

export const getStaticProps: GetStaticProps = async (context) => {
	const pageId = context?.params?.pageId;

	if (typeof pageId === 'string') {
		const page = await resolvePage(domain, pageId);

		return {
			props: page,
			revalidate: 10,
		};
	}

	return { props: {} };
};

export const getStaticPaths = async () => {
	if (isDev) {
		return {
			paths: [],
			fallback: true,
		};
	}

	const siteMaps = await getSiteMaps();

	return {
		paths: siteMaps.flatMap((siteMap) =>
			Object.keys(siteMap.canonicalPageMap).map((pageId) => ({
				params: {
					pageId,
				},
			})),
		),
		fallback: true,
	};
};

const NotionDynamicPage = (props: typeof resolvePage) => <NotionPage {...props} />;

export default NotionDynamicPage;
